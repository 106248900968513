import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Slider from 'components/Slider/Slider'
import LazyImage from 'components/shared/LazyImage'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Wrapper = styled.section`
  padding: 40px 0;
`

const ImageWrapper = styled.div`
  max-height: 600px;
  margin: 0 auto;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
`

const ImageInnerWrapper = styled.div`
  margin: 0 auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
`

const settings = {
  centerMode: true,
  infinite: true,
  draggable: false,
  slidesToShow: 1,
  speed: 500,
  variableWidth: true,
}

const Gallery = ({ images }) => {
  const slides =
    images &&
    images.map(({ src, alt }, index) => (
      <ImageWrapper key={index}>
        <ImageInnerWrapper>
          <Zoom>
            <LazyImage galleryImage src={src} alt={alt} cover />
          </Zoom>
        </ImageInnerWrapper>
      </ImageWrapper>
    ))

  return (
    <Wrapper>
      <Slider carousel="centered" settings={settings} items={slides} />
    </Wrapper>
  )
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Gallery
