/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Content from 'components/shared/Content'
import bgImage from 'assets/images/Layer-14.jpg'
import LazyImage from 'components/shared/LazyImage'
import Card from 'components/Project/Card'
import Carousel from 'react-slick'
import useMedia from 'hooks/useMedia'

const Wrapper = styled.section`
  position: relative;
  display: flex;
  padding: 40px 0 0;
  flex-direction: column;
  align-items: center;
  background: url(${bgImage}) no-repeat center;
  background-size: cover;
  z-index: 2;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: rgba(227, 6, 19, 0.9);
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 60px 0 0;
  }
  @media (min-width: 1330px) {
    padding: 80px 0 0;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0 50px;
  @media (min-width: 1330px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledContent = styled(Content)`
  width: 100%;
  z-index: 4;
`

const ThumbnailsWrapper = styled.div`
  width: 100%;
  @media (min-width: 1330px) {
    max-width: 850px;
  }
`

const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`

const CarouselWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 600px;
  overflow: hidden;
`

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Summary = styled.div`
  margin: 20px 0 0 0;
  display: grid;
  gap: 20px 50px;
  grid-template-columns: repeat(1, 1fr);
  color: ${({ theme }) => theme.colors.white};
  max-width: 600px;
  ${({ theme }) => theme.mq.s} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1330px) {
    margin: 0 0 0 20px;
  }
`

const Detail = styled.h4`
  white-space: pre;
  font-size: ${({ theme }) => theme.fontSize.lg};
  line-height: 28px;
  @media (min-width: 1330px) {
    white-space: normal;
  }
  @media (min-width: 1500px) {
    white-space: pre;
  }
`

const Bold = styled.span`
  font-weight: ${({ theme }) => theme.superBold};
`

const Thumbnail = styled(ImageWrapper)`
  position: relative;
  z-index: 2;
  user-select: none;
  margin: 10px 0;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: 0.3s;
    background-color: ${({ isActive }) =>
      isActive ? 'transparent' : 'rgba(0, 0, 0, 0.4)'};
    ${({ theme }) => theme.mq.lg} {
      content: '';
    }
  }
  ${({ theme }) => theme.mq.lg} {
    cursor: pointer;
    margin: 0;
    border-right: 20px solid transparent;
  }
`

const Project = ({
  data: { title, images, description, link, informations },
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const sliderRef = useRef(null)

  const matchesLG = useMedia('(min-width: 992px)')

  const afterSlide = slideIndex => {
    setCurrentIndex(slideIndex)
  }

  const handleSlide = index => {
    sliderRef.current.slickGoTo(index)
  }

  let imageHeight = description.length <= 400 && description.length + 180
  imageHeight =
    description.length > 400 ? description.length * 0.8 + 180 : imageHeight
  imageHeight =
    description.length >= 1100 ? description.length * 0.8 : imageHeight

  const slides =
    images &&
    images.map(({ src, alt }, index) => (
      <SlideWrapper key={index}>
        <LazyImage height={imageHeight} cover src={src} alt={alt} />
      </SlideWrapper>
    ))

  const thumbnails =
    images &&
    images.map(({ src, alt }, index) => (
      <Thumbnail
        key={index}
        onClick={() => handleSlide(index)}
        isActive={currentIndex === index}
      >
        <LazyImage cover height={matchesLG && 250} src={src} alt={alt} />
      </Thumbnail>
    ))

  const custom = {
    infinite: false,
    slidesToShow: images && images.length < 3 ? images.length : 3,
    speed: 500,
    arrows: false,
  }

  return (
    <Wrapper id="project">
      <StyledContent>
        <Card
          title={title}
          afterSlide={afterSlide}
          description={description}
          link={link}
          slides={slides}
          refferer={sliderRef}
        />
        <InnerWrapper>
          <ThumbnailsWrapper>
            <Gallery>{thumbnails}</Gallery>
            {images && images.length >= 2 && (
              <CarouselWrapper>
                <Carousel {...custom}>{thumbnails}</Carousel>
              </CarouselWrapper>
            )}
          </ThumbnailsWrapper>
          <Summary>
            {informations &&
              informations.map(
                ({ invDescInformationName, invDescInformationText }) => (
                  <Detail key={invDescInformationName}>
                    <Bold>{invDescInformationName}</Bold>
                    <br />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: invDescInformationText,
                      }}
                    />
                  </Detail>
                )
              )}
          </Summary>
        </InnerWrapper>
      </StyledContent>
    </Wrapper>
  )
}

Project.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.string,
    link: PropTypes.string,
    informations: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

export default Project
