import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import SEO from 'components/shared/SEO'
import Slider from 'components/Slider/Slider'
import Project from 'components/Project/Project'
import Gallery from 'components/Gallery/Gallery'
// import Contact from 'components/Contact/Contact'
import { LangContext } from 'contexts/LangContext'

const InvestmentLayout = ({ data }) => {
  const { lang } = useContext(LangContext)
  const allLangInv = data.wpgraphql.offerPlural.nodes

  const investmentEN = allLangInv.find(({ slug }) => slug.match(`en_(.*?)`))
    .PageOfferSingle

  const investmentDE = allLangInv.find(({ slug }) => slug.match(`de_(.*?)`))
    .PageOfferSingle

  const investment = {
    EN: investmentEN,
    DE: investmentDE,
  }

  const allSlides = investment[lang].invSlider
    .map(({ invSliderImg }) => ({
      src: invSliderImg.imageFile.childImageSharp.fluid,
      alt: invSliderImg.altText || 'slide image',
    }))
    .filter(({ src }) => src)

  const slides = allSlides.map((slide, index) => {
    const langSlide = investment[lang].invSlider[index]

    return {
      ...slide,
      highlightedText:
        langSlide.invSliderTitle &&
        langSlide.invSliderTitle.replace(/\{(.*?)\}/g, '').trim(),
      text:
        langSlide.invSliderTitle &&
        langSlide.invSliderTitle.match(/\{(.*?)\}/g)[0].replace(/[{()}]/g, ''),
    }
  })

  const projectImages =
    investment[lang].invDescGallery &&
    investment[lang].invDescGallery
      .map(({ invDescGalleryImg }) => ({
        src: invDescGalleryImg.imageFile.childImageSharp.fluid,
        alt: invDescGalleryImg.altText || 'slide image',
      }))
      .filter(({ src }) => src)

  const projectData = {
    title: investment[lang].invDescTitle,
    description:
      investment[lang].invDescText &&
      investment[lang].invDescText.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' '),
    link: investment[lang].invDescBtnUrl || '/',
    images: projectImages,
    informations: investment[lang].invDescInformations,
  }

  const galleryImages =
    investment[lang].invGallery &&
    investment[lang].invGallery
      .map(({ invGalleryItem }) => ({
        src: invGalleryItem && invGalleryItem.imageFile.childImageSharp.fluid,
        alt: (invGalleryItem && invGalleryItem.altText) || 'slide image',
      }))
      .filter(({ src }) => src)

  // const localization = {
  //   city: investment[lang].invFormHeading,
  //   name: investment[lang].invFormTitle,
  //   image: investment[lang].invFormImg.imageFile.childImageSharp.fluid,
  //   imageAlt:
  //     investment[lang].invFormImg.altText ||
  //     `${investment[lang].invFormHeading} office image`,
  //   localization: [
  //     investment[lang].invFormAddress,
  //     `${investment[lang].invFormEmail || ''}\n${
  //       investment[lang].invFormPhone || ''
  //     }`,
  //   ],
  // }

  // const source = data.wpgraphql.offerPlural.nodes
  //   ? data.wpgraphql.offerPlural.nodes[0].title
  //   : 'Hauptseite'

  return (
    <>
      <SEO
        title={data.wpgraphql.offerPlural.nodes[0].title}
        lang={lang.toLowerCase()}
      />
      <div id="home" />
      {slides && <Slider items={slides} scrollTo="#project" />}
      <main>
        <Project data={projectData} />
        {galleryImages && <Gallery images={galleryImages} />}
        {/* <Contact source={source} mainLocalization={localization} /> */}
      </main>
    </>
  )
}

export const query = graphql`
  query SingleInvestment($id: String!) {
    wpgraphql {
      offerPlural(where: { title: $id }) {
        nodes {
          title
          slug
          PageOfferSingle {
            invDescTitle
            invCardCity
            invCardDesc
            invCardDistrict
            invCardLabelText
            invDescBtnText
            invDescBtnUrl
            invDescInformations {
              invDescInformationName
              invDescInformationText
            }
            invDescText
            invFormAddress
            invFormEmail
            invFormHeading
            invFormPhone
            invFormTitle
            invFormImg {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            invSlider {
              invSliderTitle
              invSliderImg {
                altText
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxHeight: 1080) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            invDescGallery {
              invDescGalleryImg {
                altText
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxHeight: 1080) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            invGallery {
              invGalleryItem {
                altText
                sourceUrl
                imageFile {
                  childImageSharp {
                    fluid(maxHeight: 1080) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

InvestmentLayout.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
}

export default InvestmentLayout
