/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Heading from 'components/shared/Heading'
import Button from 'components/shared/Button'
import Carousel from 'react-slick'
import { LangContext } from 'contexts/LangContext'
import triangleWhite from 'assets/icons/triangle-white.png'

const Wrapper = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${({ theme, red }) => (red ? theme.colors.white : theme.colors.dark)};
  background-color: ${({ theme, red }) =>
    red ? theme.colors.red : theme.colors.white};
  ${({ theme }) => theme.mq.s} {
    flex-direction: row;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    left: -9px;
    bottom: -60px;
    background: url(${triangleWhite}) no-repeat center;
  }
`

const HeadingWrapper = styled.div`
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    text-align: left;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 30px;
  z-index: 1;
  ${({ theme }) => theme.mq.lg} {
    flex-basis: 50%;
    padding: 40px 60px;
  }
`

const Description = styled.p`
  padding: 30px 0;
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.fontSize.md};
  line-height: 26px;
`

const CarouselWrapper = styled.div`
  position: relative;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    flex: 1;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 10px auto 0;
  ${({ theme }) => theme.mq.s} {
    width: auto;
  }
`

const custom = {
  infinite: true,
  draggable: false,
  slidesToShow: 1,
  speed: 1000,
}

const Card = ({ title, description, afterSlide, slides, link, refferer }) => {
  const titleText = title
    ? title.match(/\{(.*?)\}/g)[0].replace(/[{()}]/g, '')
    : 'description'

  const highlightedText = title
    ? title.replace(/\{(.*?)\}/g, '').trim()
    : 'Project'

  const { lang } = useContext(LangContext)

  return (
    <Wrapper>
      <Content>
        <HeadingWrapper>
          <Heading
            title={titleText}
            highlight={highlightedText}
            order="highlightFirst"
          />
        </HeadingWrapper>
        <Description>{description}</Description>
        <ButtonWrapper>
          <Button as="a" href={link} red>
            {lang === 'EN' && 'Visit website'}
            {lang === 'DE' && 'Besuche die Website'}
          </Button>
        </ButtonWrapper>
      </Content>
      <CarouselWrapper>
        <Carousel
          {...custom}
          afterChange={current => afterSlide(current)}
          ref={refferer}
        >
          {slides}
        </Carousel>
      </CarouselWrapper>
    </Wrapper>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  slides: PropTypes.arrayOf(PropTypes.element).isRequired,
  refferer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  afterSlide: PropTypes.func.isRequired,
}

export default Card
